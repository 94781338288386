module.exports = [{
      plugin: require('/home/servir/Nahual/Exeboard/dev/exeboard-landing/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-111297327-1"],"pluginConfig":{"head":false,"exclude":[]}},
    },{
      plugin: require('/home/servir/Nahual/Exeboard/dev/exeboard-landing/node_modules/gatsby-plugin-facebook-analytics/gatsby-browser.js'),
      options: {"plugins":[],"appId":"1085917361984109","xfbml":false,"includeInDevelopment":false,"debug":false},
    },{
      plugin: require('/home/servir/Nahual/Exeboard/dev/exeboard-landing/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"404914291635219"},
    },{
      plugin: require('/home/servir/Nahual/Exeboard/dev/exeboard-landing/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
